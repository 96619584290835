var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "b-modal",
    {
      attrs: {
        id: "modal-cancelation",
        "no-close-on-backdrop": "",
        "hide-header-close": "",
        "hide-footer": "",
        size: "md",
        centered: "",
      },
      on: { hidden: _vm.onHideActions },
      scopedSlots: _vm._u([
        {
          key: "modal-title",
          fn: function () {
            return [
              _c("p", { staticClass: "h4" }, [
                _vm._v(" " + _vm._s(_vm.actionData.modalTitle) + " "),
              ]),
            ]
          },
          proxy: true,
        },
      ]),
    },
    [
      _c(
        "FormulateForm",
        {
          ref: "cancelationForm",
          attrs: { name: "cancelationForm" },
          model: {
            value: _vm.form,
            callback: function ($$v) {
              _vm.form = $$v
            },
            expression: "form",
          },
        },
        [
          _c(
            "b-container",
            [
              _c(
                "b-row",
                { attrs: { "align-h": "center" } },
                [
                  _c(
                    "b-col",
                    { staticClass: "d-flex flex-column", attrs: { md: "12" } },
                    [
                      _c("FormulateInput", {
                        staticClass: "w-100",
                        attrs: {
                          id: "observation-input",
                          name: "observation",
                          type: "textarea",
                          validation:
                            _vm.action === "Disapprove" ||
                            _vm.action === "AskChanges"
                              ? "required"
                              : "",
                          rows: "4",
                          label: _vm.$t("Observação"),
                        },
                      }),
                    ],
                    1
                  ),
                  _c(
                    "b-col",
                    { attrs: { md: "12" } },
                    [
                      _c(
                        "b-card-actions",
                        {
                          attrs: {
                            "show-loading": _vm.busy,
                            "action-collapse": "",
                            title: _vm.$t("Anexos"),
                          },
                        },
                        [
                          _c("files-form", {
                            attrs: { files: _vm.files, "is-read-only": false },
                            on: {
                              "set-files": _vm.setFiles,
                              "remove-file": _vm.removeFile,
                            },
                          }),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                ],
                1
              ),
              _c(
                "b-row",
                { staticClass: "mt-1" },
                [
                  _c(
                    "b-col",
                    {
                      staticClass: "d-flex justify-content-end",
                      staticStyle: { gap: "10px" },
                    },
                    [
                      _c("e-button", {
                        attrs: {
                          text: _vm.$t("Voltar"),
                          "text-shortcuts": ["ESC"],
                          variant: "danger",
                        },
                        on: { click: _vm.onHideModal },
                      }),
                      _c("e-button", {
                        attrs: {
                          variant: "primary",
                          type: "submit",
                          busy: _vm.busy,
                          text: _vm.$t("Confirmar"),
                        },
                        on: { click: _vm.onConfirm },
                      }),
                    ],
                    1
                  ),
                ],
                1
              ),
            ],
            1
          ),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }